export const i18n_english = {
    translation: {
        lang: "en",
        Buttons: {
            save: "Save",
            cancel: "Cancel",
        },
        HomePage: {
            title: "Here, you can find help.",
            introduction:
                "Here, you can find different points and places of contact where you can find help, in case you have a problem.",
                coronainfo:"If you have any questions regarding corona you should check out the FAQ on the ETH-Website at ethz.ch.",
                notfalltitle: "Emergencies",
                alarmzentraletitle: "Emergency Desk",
                    alarmzentralebeschr:"In case of an emergency, you can contact the ETH internal Emergency Desk: 888 (internal phones) or 044 342 11 88 (external phones).",
                extnotfalltitle: "External  Emergency Services",
                    extnotbeschr: "In life-threatening situations, you should also contact the external emergency services: Fire Brigade (118), International Emergency Number (112), Police (117), Ambulance (144). After alerting the external emergency services, you should  report an incident to the Emergency Desk as well.",
                notfallweiteretitle: "Other Contact Numbers", 
                    notfallweiterebeschr: "In the event of harassment, threats and stalking, you can also call the ETH specialist unit for threats and violence instead of the  Emergency Desk (internal lines: 2 30 30, external lines: 044 632 30 30).",      
                    helpdesk: "VSETH Student Helpdesk",
                    helpdeskbeschr: "The VSETH Student Helpdesk will be a place for you to ask questions and talk about personal matters in a confidential environment. We listen to you and talk with you about personal, academic and administrative issues. We are here to support you during crucial times.",
                    psychologischeberatung: "Psychological Counselling & Help",
                psychberatungtitle:"Psychological Counseling Center",
                    psychberatungbeschr:"The Psychological Counseling Center is a service offered by UZH and ETH for all their students and staff. They give free professional psychological counselling and advice.",
                         nightlinetitle: "Nightline",
                    nightlinebeschr: "Nightsline’s motto is: We listen to you. It is a service run by students for students for free. You can give them a call and talk to them about anything you want, completely free of charge. In case you prefer, there is also a chat option.",
                psychweiteretitle: "Other Contact Points",
                    psychweiterebeschr: "Die Dargebotene Hand: phone 143 (German, French, Italian). You can always call here if you have something on your mind. Psychiatrist in case of Emergency:  0800 33 66 55 (Aerztefon). University Hospital Zurich: 044 255 11 11 (Emergency Unit). Crisis Intervention Centre (KIZ) Zurich: 044 296 73 10, Winterthur 052 264 37 00",
            respekttitle:"Respect Harassment Discrimination",
                bercoachtitle: "Counselling & Coaching at ETH",
                    bercoachintro: "Conducts free and confidential talks on study-related and personal topics. It is a good first point of contact if you are unsure how to deal with a problem or are looking for advice.",
                    respstelletitle: "Internal advice and conciliation service Respect",
                    respstelleintro: "The Respect Counseling and Mediation Center offers assistance and counseling on the topics of bullying, harassment and discrimination. Every request is treated confidentially and possible steps and procedures are discussed or planned together.",
                    unangemessenestitle:" Inappropriate behaviour and sexual harassment",
                    unangemessenesbeschr: "If you think you may have experienced or witnessed inappropriate behaviour or sexual harassment, you can look here.",
                ombudstitle: "Ombudspersons and confidants",
                    ombudsbeschr: "The ombudspersons are a general contact point for conflicts that cannot be solved by direct communication and for reporting suspected illegal actions. The confidants can help you with issues concerning research integrity and scientific misconduct. Both are independent and treat all information they receive confidentially.",
                avethtitle: "AVETH Counselling",
                    avethbeschr: "If you are a doctoral student you might be interested in the counseling service of AVETH. They provide guidance, support, and an open ear to doctoral students and postdocs in difficult situations with their boss and colleagues. Help them navigate the ETH bureaucracy by making them aware of the various support resources at ETH, be it legal, institutional, or otherwise.",
                respektextern: "Other contact points",
                    respektexternbeschr:"Victim Support Switzerland: Victim support is for everyone who has been the victim of a criminal act in Switzerland.",
            studienalltag: "Problems in everyday study life",
                administrativestitle: "Administrative studies",
                    administrativesbeschr:"Here you will find all the information you need if you have an administrative problem at ETH. The Registrar's Office is often a good first point of contact, whether you want to change your degree programme or have problems with your enrolment.",
                 finanziellestitle:"Financial",
                    finanziellesbeschr:"Here you will find information on financial matters as well as the possibilities of a scholarship or other financial support.",
                wohnsituationtitle: "Housing situation",
                    wohnsituationbeschr:"ETH offers a housing agency and many resources for finding a flat or a shared room yourself.",
                rechtsberatungtitle: "Legal advice",
                    rechtsberatungbeschr: "If you have legal problems, for example with your lessor or with fines, or if you simply have legal questions, it is worth contacting the legal advice centre. The legal advice team from VSUZH offers legal advice to students free of charge (CHF 5 if you are not a VSETH member). If you need legal advice on study-specific issues (e.g. you believe that your performance has been assessed unfairly), you can find information on the ETH legal advice page. If you need additional help, you can also contact hopo@vseth.ethz.ch.",
               austauschtitle: "International students",
                    austauschbeschr: "If you need help or information on questions such as entry, visas, residence permits or health insurance, take a look here:",
                behinderungtitle: " Disability advisory service",
                    behinderungbeschr:"The Disability Advisory Service is the first point of contact for disability-​specific questions and problems.",
                kindtitle: "Studying as a parent",
                    kindbeschr:"Here you can find out about the different options you have at ETH in balancing family obligations and full-​time studies.",



                kontakttitle:"Not found what you're looking for?",
                    kontaktbeschr:"You can write us to hopo(at)vseth.ethz.ch with your questions and feedback.",
                      },
        AsyncActionButton: {
            cancel: "Cancel",
        },
        ReloadButton: {
            reload: "Reload",
        },
        Navbar: {
            speakupnavbar: "Speak up ETH",
            agchancengleichheit: "working group equal opportunities",
            startseite: "Help",
            helpdesk: "Student Helpdesk"
        },
        Speakup: {
                intro: "Have you experienced anything related to sexism, homophobia, transphobia, racism, sexual harassment, or any other form of ETH-related discrimination, misconduct, or violence? Would you like to share your story, anonymously or not? The Equal Opportunity Working Group wants to give you a voice!",
                verlinkung: "Check out our website to get more information."
        },
        AGequal: {
            title:"Equal Opportunities Working Group",
            beschreibung:"The Equal Opportunities Working Group was founded in 2018 as a VSETH working group to accompany the implementation of the #wiegETHs survey (https://vseth.ethz.ch/politik/wiegeths/) on student satisfaction. The working group deals with various aspects of equal opportunities at ETH. Various projects have emerged from it, such as the speakupETH project. We are a working group created by students for students.  The aim is to provide a platform to enable everyone at ETH to get involved in these issues. If you have an idea for a project or would like to exchange ideas on the topic of equal opportunities, simply send us an email (hopo(at)vseth.ethz.ch)! All students are welcome."      
        },
        Helpdesk: {
            title: "Student Helpdesk",
            beschr:"VSETH is working on establishing a student help desk, enabling you to reach out to specially trained fellow students for help, privately and confidentially.",
            beschr2: "Starting in spring 2022, VSETH Student Helpdesk will be a place for you to ask questions and talk about personal matters in a confidential environment.",
            beschr3: "We listen to you and talk with you about personal, academic and administrative issues. ",
            beschr4: "We will not take any action without your permission during the whole process.",
            beschr5: "We are here to support you during crucial times.",
            vorgehen_title:"Our Procedure",
            werwirsind_title: "Who we are",
            werwirsind_txt: "We are students at ETH from various areas of study and in different years. We engage ourselves voluntarily for the VSETH Student Helpdesk. ",
            grundsaetze_title: "Our principles",
            respektvoll_title:"Respectful",
            respektvoll_txt:"We take your concerns seriously and deal with them in a responsible and supportive way",
            vertraulich_title:"Confidential ",
            vertraulich_txt:"We treat your request discreetly and do not pass on any information without your consent.",
            persönlich_title:"Personal",
            persönlich_txt:"We support you as a student and are available for one-on-one discussions.",
            zuhören_title:"Listen",
            zuhören_txt:"We give you a framework to raise your concerns and are there for you.",
            unterstützen_title:"Support",
            unterstützen_txt:"We help you to de-escalate conflicts and accompany you to talks if you wish.",
            informieren_title:"Inform",
            informieren_txt:"We will explain to you the rights and options available to you and, if you wish, put you in touch with the relevant people for your concerns.",
            contacttitle: "Contact",
            faq: "",
            linkfaq: "You can find frequently asked questions here",
            contact_txt:"Below you can find contact details of our volunteers. They can assist you with deescalation and provide advice in personal, academic or administrative matters. Confidentiality is our top priority, meaning we will only refer you to other ressources at your explicit request. Conversations can take place via email or in person, depending on your preference."
        },
    },
};
