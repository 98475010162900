
export const i18n_german = {
    translation: {
        lang: "de",
        example: {
            title: "Übersetzungen sind grossartig!",
            introduction:
                "Viele Angehörige der ETH Zürich sprechen kein Deutsch, deshalb ist es sehr wichtig, dass die IT Tools in mehrere Sprachen übersetzt werden. Dieses Template zeigt wie das gemacht werden kann.",
            buttonText: "Sprache wechseln",
        },
        Buttons: {
            save: "Speichern",
            cancel: "Cancel",
        },
        HomePage: {
            title: "Hier kannst du Hilfe finden",
            introduction:
                "Hier findest du verschiedene Anlaufstellen, an die du dich wenden kannst, wenn du ein Problem hast oder nicht mehr weiter weisst.",
            coronainfo:"Falls du Fragen zu Coronathemen hast, schau unbedingt beim FAQ der ETH auf ethz.ch vorbei.",
                notfalltitle: "Notfälle",
                alarmzentraletitle: "Alarmzentrale",
                    alarmzentralebeschr:"Falls es gerade einen Notfall gibt, kannst du die ETH-interne Alarmzentrale kontaktieren: 888 (interne Telefonanschlüsse) oder 044 342 11 88 (externe Telefonanschlüssen).",
                extnotfalltitle: "Externe Notfalldienste",
                    extnotbeschr: "Bei lebensbedrohlichen Situationen solltet ihr auch die externen Notfalldienste kontaktieren: Feuerwehr (118), Internationale Notruf-Nr. (112), Polizei (117), Ambulanz (144). Nach der Alarmierung der externen Notfalldienste solltest du auch die Alarmzentrale alarmieren.",
                notfallweiteretitle: "Andere Kontaktnummern",
                    notfallweiterebeschr: "Bei Belästigung, Drohung und Stalking könnt ihr statt der Alarmzentrale auch die Fachstelle Bedrohung & Gewalt anrufen (Interne Anschlüsse: 2 30 30, Externe Anschlüsse: 044 632 30 30)",
                helpdesk: "VSETH Student Helpdesk",
                    helpdeskbeschr: "Der VSETH Student Helpdesk dir einen vertraulichen Ort, um Fragen zu stellen und deine Anliegen in einem geschützten Umfeld anzubringen. Wir hören zu und besprechen mit dir persönliche, akademische oder administrative Anliegen. Wir sind für dich da, um dich in schwierigen Zeiten zu unterstützen.",
                    psychologischeberatung: "Psychologische Beratung & Hilfe",
                psychberatungtitle:"Psychologische Beratungsstelle",
                    psychberatungbeschr:"Die psychologische Beratungsstelle ist ein Angebot der UZH und ETH für alle ihre Studierenden und Mitarbeitenden. Sie bietet kostenlose professionelle psychologische Beratung und Betreuung an.",
                nightlinetitle: "Nightline",
                    nightlinebeschr: "Das Motto der Nightline ist: Wir sind da, um zuzuhören. Nightline ist ein kostenloser Service, der von Studierenden für Studierende betrieben wird. Du kannst sie anrufen und mit ihnen über alles reden, was dir auf dem Herzen liegt, völlig kostenlos. Falls du es vorziehst, gibt es auch eine Chat-Option.",
                psychweiteretitle: "Weitere Anlaufstellen",
                    psychweiterebeschr: "Die Dargebotene Hand: 143  (Deutsch, Französisch, Italienisch). Hier kannst du immer anrufen, wenn dir was auf dem Herzen liegt. Notfallpsychiater: 0800 33 66 55 (Ärztefon Kanton Zürich). Notfall Universitätsspital Zürich: 044 255 11 11. Kriseninterventionszentrum (KIZ) Zürich: 044 296 73 10, Winterthur 052 264 37 00",
            respekttitle:"Respekt, Belästigung, Diskriminierung",
                bercoachtitle: "Beratung und Coaching der ETH",
                    bercoachintro: "Führt kostenlos und vertraulich Gespräche zu studienbezogenen und persönlichen Themen durch. Es ist eine gute Anlaufstelle, wenn man sich unsicher ist, wie man mit einem Problem umgehen soll oder Rat sucht.",
                respstelletitle: "Respektstelle",
                    respstelleintro: "Die Beratungs-​ und Schlichtungsstelle Respekt bietet Hilfestellung und Beratung zu den Themen Mobbing, Belästigung und Diskriminierung. Jede Anfrage wird vertraulich behandelt und mögliche Schritte und Vorgehensweisen werden gemeinsam besprochen bzw. geplant.",
                    unangemessenestitle:"Unangemessenes Verhalten und Sexuelle Belästigung",
                    unangemessenesbeschr: "Falls du denkst, dir könnte unangemessenes Verhalten oder sexuelle Belästigung widerfahren sein oder du solches Verhalten beobachtet haben könntest, schau am besten hier vorbei.",
                ombudstitle: "Ombuds- und Vertrauenspersonen",
                    ombudsbeschr: "Die Ombudspersonen sind eine allgemeine Anlaufstelle bei Konflikten, die im direkten Gespräch nicht gelöst werden können, und bei Verdacht auf rechtlich unkorrektes Verhalten. Die Vertrauenspersonen sind Ansprechpartner für Fragen bezüglich Integrität in der Forschung und bei Verdacht auf wissenschaftliches Fehlverhalten. Beide Anlaufstellen sind unabhängig und behandeln alle Informationen vertraulich.",
                avethtitle: "AVETH Beratung",
                    avethbeschr: "Wenn du ein*e Doktorand*in bist, könnte dich der Beratungsdienst von AVETH interessieren. Sie bieten Beratung, Unterstützung und ein offenes Ohr für Doktorierende und Postdocs in schwierigen Situationen mit ihrem*r Chef*in und ihren Kollegen*innen. Sie helfen dir, dich in der ETH-Bürokratie zurechtzufinden, indem sie dich auf die verschiedenen Unterstützungsmöglichkeiten an der ETH aufmerksam machen, sei es rechtlich, institutionell oder anderweitig.",
                respektextern: "Weitere externe Anlaufstellen",
                    respektexternbeschr:"Die Opferhilfe ist für alle Menschen da, die in der Schweiz Opfer einer Straftat geworden sind",
            studienalltag: "Probleme im Studienalltag",
                administrativestitle: "Administratives im Studium",
                    administrativesbeschr:"Hier findest du alle Informationen, die du brauchst, falls du ein administratives Problem an der ETH hast. Die Kanzlei ist dabei oft eine gute erste Anlaufstelle, sei es bei Studiengangswechsel oder bei Problemen mit deiner Einschreibung.",
                finanziellestitle:"Finanzielles",
                    finanziellesbeschr:"Hier findest du Informationen zum Finanziellem sowie die Möglichkeiten eines Stipendiums oder anderer finanziellen Unterstützung.",
                wohnsituationtitle: "Wohnsituation",
                    wohnsituationbeschr:"Die ETH bietet eine Wohnungsvermittlung und viele Ressourcen, um selbst eine Wohnung oder ein WG-Zimmer zu finden.",
                rechtsberatungtitle: "Rechtsberatung",
                    rechtsberatungbeschr: "Falls du rechtliche Probleme hast zum Beispiel mit deiner vermietenden Person oder bei Bussen, oder falls du einfach rechtliche Fragen hast, lohnt es sich bei der VSUZH-Rechtsberatungsstelle zu melden. Die Rechtsberatungsstelle bietet Studierenden kostenlose (5CHF, falls man nicht VSETH-Mitglied ist) Rechtsberatung an. Falls du Rechtsberatung zu studienspezifsichen Themen (z.B. ungerechtfertigte Leistungsbewertung) brauchst, kannst du auf dich auf der Rechtsberatungsseite der ETH informieren. Falls du zusätzlich noch Hilfe brauchst, kannst du dich auch an hopo@vseth.ethz.ch wenden.",
                austauschtitle: "Austauschstudierende und ausländische Studierende",
                    austauschbeschr: "Brauchst du Hilfe oder Informationen zu Fragen wie Einreise, Visum, Aufenthaltsbewilligung oder Krankenversicherung brauchst, schau doch hier vorbei:",
                behinderungtitle: "Studium und Behinderung",
                    behinderungbeschr:"Die Beratungsstelle Studium und Behinderung ist der erste Kontakt bei behinderungsspezifischen Fragen und Problemen und unterstützt dich bei der Organisation deines Studiums.",
                kindtitle: "Studium mit Kind",
                    kindbeschr:"Hier kannst du ich über die verschiedenen Möglichkeiten Informieren, die du an der ETH hast, um die familiären Verpflichtungen und das Vollzeitstudium zu vereinbaren.",



                kontakttitle:"Nicht gefunden, wonach du suchst?",
                    kontaktbeschr:"Du kannst dich jederzeit beim VSETH melden, wenn du Unterstützung brauchst oder Feedback hast. Schreibe einfach eine Mail an hopo(at)vseth.ethz.ch.",
        },
        AsyncActionButton: {
            cancel: "Abbrechen",
        },
        ErrorScreen: {
            title: "Ein Fehler ist augetreten",
            text:
                "Probiere die Seite neu zu laden und kontaktiere den Administrator, falls das Problem sich so nicht löst.",       },
        LoadScreen: {
            loading: "Die Daten werden geladen",
        },
        Navbar: {
            speakupnavbar: "Speak up ETH",
            agchancengleichheit: "Arbeitsgruppe Chancengleichheit",
            startseite: "Hilfe",
            helpdesk: "Student Helpdesk"
        },
        Speakup: {
            intro: "Hast du etwas im Zusammenhang mit Sexismus, Homophobie, Transphobie, Rassismus, sexueller Belästigung oder einer anderen Form von Diskriminierung, Fehlverhalten oder Gewalt im Zusammenhang mit der ETH erlebt? Möchtest du deine Geschichte mit uns teilen, ob anonym oder nicht? Die AG Chancengleichheit möchte dir eine Stimme geben!",
            verlinkung: "Besuche unsere Webseite, um mehr über das Projekt zu erfahren.",
        },
        AGequal: {
            title:"AG Chancengleichheit",
            beschreibung:"Die AG Chancengleichheit wurde 2018 als AG des VSETH gegründet, um die Durchführund der #wiegETHs Umfrage (https://vseth.ethz.ch/politik/wiegeths/) zur Studienzufriedenheit zu begleiten. Die AG befasst sich mit verschiedenen Aspekten der Chancengleichheit an der ETH. Daraus sind verschiedene Projekte entstanden, wie zum Beispiel das Projekt speakupETH. Wir sind eine AG von Studierenden für Studierende.  Das Ziel ist es, eine Plattform zu bieten, um allen an der ETH zu ermöglichen, sich zu diesen Themen einzubringen. Falls du eine Idee für ein Projekt hast oder dich irgendwie zum Thema Chancengleichheit austauschen möchtest, schreibt uns einfach ganz unkompliziert eine Mail (hopo(at)vseth.ethz.ch)! Alle Studierenden sind willkommen."
        },
        Helpdesk: {
            title: "Student Helpdesk",
            beschr2: "Seit dem Frühjahrssemester 2022 bietet der VSETH Student Helpdesk dir einen vertraulichen Ort, um Fragen zu stellen und deine Anliegen in einem geschützten Umfeld anzubringen.",
            beschr3: "Wir hören zu und besprechen mit dir persönliche, akademische oder administrative Anliegen. ",
            beschr4: "Ohne dein vorheriges Einverständnis werden während des gesamten Prozesses keine Massnahmen ergriffen.",
            beschr5: "Wir sind für dich da, um dich in schwierigen Zeiten zu unterstützen.",
            vorgehen_title:"Unser Vorgehen",
            werwirsind_title: "Wer wir sind",
            werwirsind_txt: "Wir sind Studierende der ETH aus verschiedenen Studienrichtungen und Semestern. Wir engagieren uns freiwillig für den VSETH Student Helpdesk.",
            grundsaetze_title: "Unsere Grundsätze",
            respektvoll_title:"Respektvoll",
            respektvoll_txt:"Wir nehmen dein Anliegen ernst und gehen damit verantwortungsvoll und unterstützend um.",
            vertraulich_title:"Vertraulich ",
            vertraulich_txt:"Wir behandeln dein Anliegen diskret und geben keine Informationen ohne dein Einverständnis weiter.",
            persönlich_title:"Persönlich",
            persönlich_txt:"Wir unterstützen dich als Studierende auf Augenhöhe und stehen für Vier-Augen-Gespräche zur Verfügung.",
            zuhören_title:"Zuhören",
            zuhören_txt:"Wir geben dir einen Rahmen, dein Anliegen anzubringen und  sind für dich da.",
            unterstützen_title:"Unterstützen",
            unterstützen_txt:"Wir helfen dir, Konflikte zu deeskalieren und begleiten dich auf Wunsch zu Gesprächen.",
            informieren_title:"Informieren",
            informieren_txt:"Wir erklären dir, welche Rechte und Möglichkeiten dir zur Verfügung stehen und vermitteln dich auf Wunsch an die richtigen Stellen für dein Anliegen.",
            contacttitle: "Kontakt",
            contact_txt:"Gerne kannst du uns per Mail kontaktieren, um ein vertrauliches Gespräch von Angesicht zu Angesicht zu vereinbaren, oder um deine Frage direkt zu stellen. Die Kontaktdaten unserer Helfenden findest du gleich anschliessend:",
            linkfaq: "Antworten zu häufigen Fragen findest du hier"
            },
            Helpdeskfaq: {
                title: "FAQ Student Help Desk",
                beschr: "Diese Seite ist noch in Arbeit"
            }
    },
};
